import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';
import router from './router';

import locale from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ConfigProvider locale={locale}>
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
);
