import React, { useState } from 'react';
import { theme } from 'antd';
import ResultTable from '../../components/ResultTable.jsx';
import HomeSearchForm from '../../components/HomeSearchForm.jsx';
import { Lunar, Solar } from 'lunar-typescript';
import Loading from '../../components/Loading.jsx';
import { calculateBianGua, calculateBianGuaDizhi, calculateDongYao, calculateLowerGua, calculateLowerGuaDizhi, calculateScore, calculateUpperGua, calculateUpperGuaDizhi, calculateWuYunLiQi, dateFormat, getGuaPic, getLunarFromGongli, getSixAnimal } from '../../contract/utils.js';

import './home.css'

const Home = () => {
	const { token } = theme.useToken();
	const [data, setData] = useState({ bazi: new Array(4) })
	const [loading, setLoading] = useState(false)

	const onSubmit = (values) => {
		let temp = { ...values }
		//console.log('temp',temp)
		const solar = Solar.fromYmdHms(values.birthDay.year(), values.birthDay.month() + 1, values.birthDay.date(), values.birthDay.hour(), values.birthDay.minute(), values.birthDay.second());
		//console.log(values.birthDay.year(), values.birthDay.month() + 1, values.birthDay.date(), values.birthDay.hour(), values.birthDay.minute(), values.birthDay.second());

		var lunar = solar.getLunar();
		if (values.calendarType === '农历') {
			lunar = Lunar.fromYmdHms(values.birthDay.year(), values.birthDay.month() + 1, values.birthDay.date(), values.birthDay.hour(), values.birthDay.minute(), values.birthDay.second());
		}
		temp.lunar = lunar.toString() + ' ' + lunar.getTimeZhi() + '时'
		temp.bazi = lunar.getBaZi()
		//五运六气
		temp.wuyunliuqi = calculateWuYunLiQi(solar.getYear())

		
		//咨询时间
		if (temp.askTime === null || temp.askTime === '') {
				temp.askTimeString = dateFormat(new Date());
		} else {
				temp.askTimeString = dateFormat(temp.askTime.toDate());
		}
		//console.log('askTime',temp.askTime)
		let askTimeLunar = getLunarFromGongli(temp.askTime.year(), temp.askTime.month() + 1, temp.askTime.date(), temp.askTime.hour(), temp.askTime.minute(), temp.askTime.second());
		//console.log('askTimeLunar',askTimeLunar)
		//咨询五柱
		temp.wuzhu =askTimeLunar.getBaZi();

		//六个神兽
		//console.log('天干--->',temp.wuzhu[2].substring(0,1))
		temp.sixAnimal=getSixAnimal(temp.wuzhu[2].substring(0,1))

		//上下卦
		let upperGua = calculateUpperGua(lunar,askTimeLunar)
		let lowerGua = calculateLowerGua(lunar,askTimeLunar)
		temp.gua = { upper: upperGua, lower: lowerGua };


		//计算主卦地支
		temp.upperDizhi = calculateUpperGuaDizhi(upperGua)
		//console.log('upperDizhi', temp.upperDizhi)

		temp.lowerDizhi = calculateLowerGuaDizhi(lowerGua)
		//console.log('lowerDizhi', temp.lowerDizhi)

		//画出卦图
		temp.guaPic = [getGuaPic(upperGua), getGuaPic(lowerGua)]
		//console.log('gua-pic', temp.guaPic)

		//计算动爻
		temp.dongYao = calculateDongYao(lunar, askTimeLunar)
		//console.log('动爻索引', temp.dongYao)

		//计算变卦地支
		temp.bianGuaDizhi = calculateBianGuaDizhi(lunar, askTimeLunar)
		temp.bianGua=calculateBianGua(lunar, askTimeLunar)
		//console.log('bianGua', temp.bianGua)
		//console.log('bianGuaDizhi', temp.bianGuaDizhi)


		//咨询五柱第五柱
		temp.diwuzhu =['','']
		if(temp.dongYao>3){
			let left=temp.upperDizhi[6-temp.dongYao];
			temp.diwuzhu[0]=left
			temp.diwuzhu[1]=temp.bianGuaDizhi[6-temp.dongYao];
		}else{
			let left=temp.lowerDizhi[3-temp.dongYao];
			temp.diwuzhu[0]=left
			temp.diwuzhu[1]=temp.bianGuaDizhi[3-temp.dongYao];
		}
		//打分
		//console.log(temp.wuzhu)
		let wuzhuString=temp.wuzhu.toString().replace(/,/gi,'')+temp.diwuzhu.toString().replace(/,/gi,'')
		let wuzhuArr=[]
		for(let i=0;i<wuzhuString.length;i++){
			wuzhuArr.push(wuzhuString[i])
		}

		let guaDizhiString=temp.upperDizhi.toString().replace(/,/gi,'')+temp.lowerDizhi.toString().replace(/,/gi,'')
		let guaDizhiArr=[]
		for(let i=0;i<guaDizhiString.length;i++){
			guaDizhiArr.push(guaDizhiString[i])
		}
		//console.log(wuzhuArr,guaDizhiArr)
		temp.score=calculateScore(wuzhuArr ,guaDizhiArr)
	
		
		//console.log(temp)

		setLoading(true)
		setTimeout(() => {
			setLoading(false)
			setData(temp)
			document.title=temp.name+'的排盘';
		}, 2000)

	}

	const onPrint=()=>{
		window.print()
	}

	return (
		<>
			<HomeSearchForm onSubmit={onSubmit} onPrint={onPrint}/>
			<div id="printableArea" style={{ backgroundColor: '#fff', marginTop: 16, padding: 24, borderRadius: token.borderRadiusLG, display: 'flex' }}>
				{loading && <Loading />}
				<ResultTable data={data} />
			</div>
		</>
	);
};
export default Home;