import React from 'react'
import {getDiZhiType} from '../contract/utils'
export default function ResultTable({data:{name,age,birthPlace,lunar,bazi=new Array(4),wuyunliuqi,gua,askTimeString,wuzhu=new Array(4),upperDizhi=new Array(3),lowerDizhi=new Array(3),guaPic=new Array(8),dongYao=-1,bianGuaDizhi=new Array(3),diwuzhu=new Array(2),sixAnimal=new Array(3),score=new Array(6),tel}}) {
	 
    return (
        <table className="MsoTableGrid" border={1} cellSpacing={0} cellPadding={0} style={{ borderCollapse: 'collapse', border: 'none',flex:1 }}>
            <tbody>

            <tr style={{ height: 48 }}>
                <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                    <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>姓名</span></p>
                </td>
                <td   style={{ width: '66.6pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                    <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{name}</span></p>
                </td>
                <td  style={{ width: '80pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                    <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>年龄</span></p>
                </td>
                <td  style={{ width: '78.0pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                    <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{age}</span></p>
                </td>
                <td  colSpan={1} style={{ width: '62.05pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                    <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>出生地</span></p>
                </td>
                <td colSpan={3}  style={{ width: '68.75pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                    <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{birthPlace}</span></p>
                </td>
            </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>农历生日</span></p>
                    </td>
                    <td  colSpan={3} style={{ width: '128.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{lunar}</span></p>
                    </td>
                    <td style={{ width: '78.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>五运六气</span></p>
                    </td>
                    <td colSpan={3} style={{ width: '130.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}></span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  rowSpan={2} style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>命主四柱</span></p>
                    </td>
                    <td  rowSpan={2}  style={{ width: '66.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bazi[0]}</span></p>
                    </td>
                    <td  rowSpan={2}  style={{ width: '62.25pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bazi[1]}</span></p>
                    </td>
                    <td  rowSpan={2}  style={{ width: '78.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bazi[2]}</span></p>
                    </td>
                    <td  rowSpan={2}  style={{ width: '78.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bazi[3]}</span></p>
                    </td>
                    <td  colSpan={1} style={{ width: '32.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>上卦</span></p>
                    </td>
                    <td colSpan={2} style={{ width: '32.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>{gua?.upper}</span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    
                    <td colSpan={1} style={{ width: '32.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>下卦</span></p>
                    </td>
                    <td colSpan={2} style={{ width: '32.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{gua?.lower}</span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>咨询时间</span></p>
                    </td>
                    <td  colSpan={3} style={{ width: '128.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{askTimeString}</span></p>
                    </td>
                    <td colSpan={1}  style={{ width: '78.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>联系方式</span></p>
                    </td>
                    <td colSpan={3} style={{ width: '130.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{tel}</span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>咨询五柱</span></p>
                    </td>
                    <td   style={{ width: '66.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{wuzhu[0]}</span></p>
                    </td>
                    <td   style={{ width: '62.25pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{wuzhu[1]}</span></p>
                    </td>
                    <td   style={{ width: '78.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{wuzhu[2]}</span></p>
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{wuzhu[3]}</span></p>
                    </td>
                    <td colSpan={1} style={{ width: '32.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D0CECE', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt', background: 'lightgrey' }}>{diwuzhu[0]}</span></p>
                    </td>
                    <td colSpan={2} style={{ width: '32.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D0CECE', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal"  align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt', background: 'lightgrey' }}>{diwuzhu[1]}</span></p>
                    </td>
                </tr>
                
                <tr style={{ height: 48 }}>
                    <td style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>梅花易数</span></p>
                    </td>
                    {/* <td   style={{ width: '77pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>六亲</span></p>
                    </td> */}
                    <td  style={{ width: '77pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>六兽</span></p>
                    </td>
                    <td   style={{ width: '77pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>地支</span></p>
                    </td>
                    <td   style={{ width: '77pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>主卦</span></p>
                    </td>
                    <td   style={{ width: '77pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>动爻</span></p>
                    </td>
                    <td   style={{ width: '80pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>变卦地支</span></p>
                    </td>
                    <td colSpan={4} style={{ width: '130.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>六经评分</span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>六</span></p>
                    </td>
                    {/* <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>&nbsp;</span></p>
                    </td> */}
                    <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{sixAnimal[5]}</span></p>
                    </td>
                    <td   style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center',color:getDiZhiType(upperDizhi[0]) }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{upperDizhi[0]}</span></p>
                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>

                        <p className="MsoNormal"  align="center" style={{ textAlign: 'center' }}>
                            {guaPic[0]&&guaPic[0][0].map((l,indexL)=>{
															if(l===1){
																return <span lang="EN-US" key={indexL} style={{ fontSize: '10.0pt' }}>●</span>
															}else{
																return null
															}

														})}
                        </p>

                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D9D9D9', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        {dongYao===6&&<p className="MsoNormal" align="center" style={{ textAlign: 'start' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>x</span></p>}
                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
						{dongYao>3&&<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bianGuaDizhi[0]}</span></p>}
                    </td>
                    <td    style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>太阳经 {score[0]}</span></p>
                    </td>
                    <td   style={{ width: '200pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}></span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>爻</span></p>
                    </td>
                    {/* <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>&nbsp;</span></p>
                    </td> */}
                    <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{sixAnimal[4]}</span></p>
                    </td>
                    <td   style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												<p className="MsoNormal" align="center" style={{ textAlign: 'center',color:getDiZhiType(upperDizhi[1]) }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{upperDizhi[1]}</span></p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												<p className="MsoNormal"  align="center" style={{ textAlign: 'center' }}>
															{guaPic[0]&&guaPic[0][1].map((l,indexL)=>{
																if(l===1){
																	return <span lang="EN-US" key={indexL} style={{ fontSize: '10.0pt' }}>●</span>
																}else{
																	return null
																}

															})}
												</p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D9D9D9', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        {dongYao===5&&<p className="MsoNormal" align="center" style={{ textAlign: 'start' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>x</span></p>}                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
						 {dongYao>3&&<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bianGuaDizhi[1]}</span></p>}
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>阳明经 {score[1]}</span></p>
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}></span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>六</span></p>
                    </td>
                    {/* <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>&nbsp;</span></p>
                    </td> */}
                    <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{sixAnimal[3]}</span></p>
                    </td>
                    <td   style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center',color:getDiZhiType(upperDizhi[2]) }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{upperDizhi[2]}</span></p>
                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												<p className="MsoNormal"  align="center" style={{ textAlign: 'center' }}>
															{guaPic[0]&&guaPic[0][2].map((l,indexL)=>{
																if(l===1){
																	return <span lang="EN-US" key={indexL} style={{ fontSize: '10.0pt' }}>●</span>
																}else{
																	return null
																}

															})}
												</p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D9D9D9', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        {dongYao===4&&<p className="MsoNormal" align="center" style={{ textAlign: 'start' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>x</span></p>}
                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												{dongYao>3&&<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bianGuaDizhi[2]}</span></p>}
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>少阳经 {score[2]}</span></p>
                    </td>
                    <td  style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}></span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>经</span></p>
                    </td>
                    {/* <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>&nbsp;</span></p>
                    </td> */}
                    <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{sixAnimal[2]}</span></p>
                    </td>
                    <td   style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center',color:getDiZhiType(lowerDizhi[0]) }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{lowerDizhi[0]}</span></p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												<p className="MsoNormal"  align="center" style={{ textAlign: 'center' }}>
															{guaPic[1]&&guaPic[1][0].map((l,indexL)=>{
																if(l===1){
																	return <span lang="EN-US" key={indexL} style={{ fontSize: '10.0pt' }}>●</span>
																}else{
																	return null
																}

															})}
												</p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D9D9D9', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        {dongYao===3&&<p className="MsoNormal" align="center" style={{ textAlign: 'start' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>x</span></p>}
                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												{dongYao<4&&<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bianGuaDizhi[0]}</span></p>}
                    </td>
                    <td  style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>太阴经 {score[3]}</span></p>
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}></span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>易</span></p>
                    </td>
                    {/* <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>&nbsp;</span></p>
                    </td> */}
                    <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{sixAnimal[1]}</span></p>
                    </td>
                    <td   style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center',color:getDiZhiType(lowerDizhi[1]) }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{lowerDizhi[1]}</span></p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												<p className="MsoNormal"  align="center" style={{ textAlign: 'center' }}>
															{guaPic[1]&&guaPic[1][1].map((l,indexL)=>{
																if(l===1){
																	return <span lang="EN-US" key={indexL} style={{ fontSize: '10.0pt' }}>●</span>
																}else{
																	return null
																}

															})}
												</p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D9D9D9', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        {dongYao===2&&<p className="MsoNormal" align="center" style={{ textAlign: 'start' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>x</span></p>}
                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												{dongYao<4&&<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bianGuaDizhi[1]}</span></p>}
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>少阴经 {score[4]}</span></p>
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}></span></p>
                    </td>
                </tr>
                <tr style={{ height: 48 }}>
                    <td  style={{ width: '77.15pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>测</span></p>
                    </td>
                    {/* <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>&nbsp;</span></p>
                    </td> */}
                    <td  style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{sixAnimal[0]}</span></p>
                    </td>
                    <td   style={{ width: '32.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center',color:getDiZhiType(lowerDizhi[2]) }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{lowerDizhi[2]}</span></p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												<p className="MsoNormal"  align="center" style={{ textAlign: 'center' }}>
															{guaPic[1]&&guaPic[1][2].map((l,indexL)=>{
																if(l===1){
																	return <span lang="EN-US" key={indexL} style={{ fontSize: '10.0pt' }}>●</span>
																}else{
																	return null
																}

															})}
												</p>
                    </td>
                    <td   style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', background: '#D9D9D9', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        {dongYao===1&&<p className="MsoNormal" align="center" style={{ textAlign: 'start' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>x</span></p>}
                    </td>
                    <td  style={{ width: '36.75pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
												{dongYao<4&&<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}>{bianGuaDizhi[2]}</span></p>}
                    </td>
                    <td  style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ fontSize: '10.0pt', fontFamily: 'DengXian' }}>厥阴经 {score[5]}</span></p>
                    </td>
                    <td   style={{ width: '65.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0cm 5.4pt 0cm 5.4pt', height: 48 }}>
                        <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span lang="EN-US" style={{ fontSize: '10.0pt' }}></span></p>
                    </td>
                </tr>
            </tbody>
        </table>

    )
}
