import React from 'react'
import './loading.css'
export default function Loading() {
	return (
		<div style={{position:'fixed',top:0,left:0,zIndex:9999,display:'flex',height:'100vh',width:'100vw',justifyContent:'center',alignItems:'center',backgroundColor:'rgba(0, 58, 100, 0.1)'}}>
			<div className="index-banner-circle">
					<img alt="" src={require('../assets/images/circle1.png')} className="index-banner-circle-1" />
					<img alt="" src={require('../assets/images/circle2.png')}  className="index-banner-circle-2" />
			</div>
		</div>
	)
}
