import React from 'react'
import { Button, Col, DatePicker, Form, Input, Radio, Row, Space, theme } from 'antd';
import {PrinterOutlined} from '@ant-design/icons';

export default function HomeSearchForm({onSubmit,onPrint}) {
	const { token } = theme.useToken();
	const [form] = Form.useForm();
	const formStyle = {
		maxWidth: 'none',
		background: '#fff',
		borderRadius: token.borderRadiusLG,
		padding: 24,
	};
	const getAge=(birthday)=> {
    var today = new Date();
    var birthDate = new Date(birthday);

    if (today > birthDate) {
        return today.getFullYear() - birthDate.getFullYear();
    } else {
        return today.getFullYear() - birthDate.getFullYear() - 1;
    }
	}
	const onFinish = (values) => {
		values.age=getAge(values.birthDay)
		onSubmit&&onSubmit(values)
	};

	return (
		<Form className='no-print' form={form} name="advanced_search" style={formStyle} onFinish={onFinish} labelAlign={'right'} labelCol={{span:6}} >
			<Row gutter={24}>
				<Col span={8}>
					<Form.Item
						name={`name`}
						label={`姓名`}
						rules={[
							{
								required: true,
								message: '请输入姓名',
							},
						]}
					>
						<Input placeholder="请输入姓名" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name={`calendarType`}
						label={`日历`}
						rules={[
							{
								required: true,
								message: '请选择日历类型',
							},
						]}
					>
						<Radio.Group
							options={[{ label: '公历', value: '公历' }, { label: '农历', value: '农历' }, { label: '四柱', value: '四柱' }]}
							buttonStyle="solid"
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name={`birthDay`}
						label={`生日`}
						rules={[
							{
								required: true,
								message: '请选择生日',
							},
						]}
					>
						<DatePicker showTime />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name={`sex`}
						label={`性别`}
						rules={[
							{
								required: true,
								message: '请选择性别',
							},
						]}
					>
						<Radio.Group
							options={[{ label: '男', value: '男' }, { label: '女', value: '女' }]}
							value={'公历'}
							buttonStyle='solid'
						/>
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						name={`birthPlace`}
						label={`出生地`}
						rules={[
							{
								required: true,
								message: '请选择出生地',
							},
						]}
					>
						{/* <Cascader options={PlaceOptions} placeholder='请选择出生地' /> */}
						<Input placeholder="请输入出生地" />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						name={`tel`}
						label={`电话`}
					>
						<Input placeholder="请输入联系方式" />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						name={`askTime`}
						label={`咨询时间`}
						rules={[
							{
								required: true,
								message: '请选择咨询时间',
							},
						]}
					>
						<DatePicker showTime />
					</Form.Item>
				</Col>

			</Row>
			<div style={{ textAlign: 'right' }}>
				<Space size="small">
					<Button type="primary" htmlType="submit">
						开始排盘
					</Button>
					<Button
						onClick={() => {
							form.resetFields();
						}}
					>
						重置
					</Button>

					<Button
						onClick={() => {
							onPrint&&onPrint()
						}}
						style={{marginLeft:16}}
					>
						<PrinterOutlined />
						打印
					</Button>

				</Space>
			</div>
		</Form>
	);
}
