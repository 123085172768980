import { Lunar, Solar } from 'lunar-typescript';
/**
十天干名,阴阳属性,五行属性,对应数字
甲,阳,木,1
乙,阴,木,2
丙,阳,火,3
丁,阴,火,4
戊,阳,土,5
己,阴,土,6
庚,阳,金,7
辛,阴,金,8
壬,阳,水,9
癸,阴,水,10
 */
const TIANGAN=['甲','乙','丙','丁','戊','己','庚','辛','壬','癸'];

/**
 * 六个神兽
 */
const SIX_ANIMAL=['朱雀','勾陈','腾蛇','白虎','玄武','青龙','朱雀','勾陈','腾蛇','白虎','玄武','青龙']



/**
子,阳,水,1
丑,阴,土,2
寅,阳,木,3
卯,阴,木,4
辰,阳,土,5
巳,阴,火,6
午,阳,火,7
未,阴,土,8
申,阳,金,9
酉,阴,金,10
戌,阳,土,11
亥,阴,水,12
 */

const DIZHI=['子','丑','寅','卯','辰','巳','午','未','申','酉','戌','亥'];

const DIZHI_TYPE=['阳','阴','阳','阴','阳','阴','阳','阴','阳','阴','阳','阴']

/**
乾，阳，金，1，
兑，阴，金，2，
离，阴，火，3，
震，阳，木，4，
巽，阴，木，5，
坎，阳，水，6，
艮，阳，土，7，
坤，阴，土，8，
 */

const GUA=['乾','兑', '离', '震', '巽', '坎', '艮', '坤'];

//const GUA_OFFSITE=['坤','艮', '坎','巽', '震', '离', '兑', '乾'];

export const GUAPIC=[
  [[1,0],[1,0],[1,0]],
  [[1,1],[1,0],[1,0]],
  [[1,0],[1,1],[1,0]],
  [[1,1],[1,1],[1,0]],
  [[1,0],[1,0],[1,1]],
  [[1,1],[1,0],[1,1]],
  [[1,0],[1,1],[1,1]],
  [[1,1],[1,1],[1,1]],
]


const GUA_MAP=new Map()
GUA_MAP.set('101010','乾');
GUA_MAP.set('111010','兑');
GUA_MAP.set('101110','离');
GUA_MAP.set('111110','震');
GUA_MAP.set('101011','巽');
GUA_MAP.set('111011','坎');
GUA_MAP.set('101111','艮');
GUA_MAP.set('111111','坤');



const GUA_DIZHI_UPPER=[
  ['戌','申','午'],
  ['未','酉','亥'],
  ['巳','未','酉'],
  ['戌','申','午'],
  ['卯','巳','未'],
  ['子','戌','申'],
  ['寅','子','戌'],
  ['酉','亥','丑'],
]

const GUA_DIZHI_LOWER=[
  ['辰','寅','子'],
  ['丑','卯','巳'],
  ['亥','丑','卯'],
  ['辰','寅','子'],
  ['酉','亥','丑'],
  ['午','辰','寅'],
  ['申','午','辰'],
  ['卯','巳','未']
]


/**
 * @type {Map<String,String>}
 */
const SCORE_MAP=new Map()
SCORE_MAP.set("亥","亥,子,壬,癸,申,酉,庚,辛")
SCORE_MAP.set("子","亥,子,壬,癸,申,酉,庚,辛")
SCORE_MAP.set("寅","寅,卯,甲,乙,亥,子,壬,癸")
SCORE_MAP.set("卯","寅,卯,甲,乙,亥,子,壬,癸")
SCORE_MAP.set("巳","巳,午,丙,丁,寅,卯,甲,乙")
SCORE_MAP.set("午","巳,午,丙,丁,寅,卯,甲,乙")
SCORE_MAP.set("申","申,酉,庚,辛,戊,己,丑,辰,未,戌")
SCORE_MAP.set("酉","申,酉,庚,辛,戊,己,丑,辰,未,戌")
SCORE_MAP.set("丑","戊,己,丑,辰,未,戌,巳,午,丙,丁")
SCORE_MAP.set("辰","戊,己,丑,辰,未,戌,巳,午,丙,丁")
SCORE_MAP.set("未","戊,己,丑,辰,未,戌,巳,午,丙,丁")
SCORE_MAP.set("戌","戊,己,丑,辰,未,戌,巳,午,丙,丁")


/**
 * 获取天干索引
 * @param {String} str 
 * @returns 
 */
const getTianGanIndex=(str)=>{
  return TIANGAN.indexOf(str)+1;
}

/**
*
*甲，乙＝青龙
*丙，丁＝朱雀
*戊＝勾陈
*己＝腾蛇
*庚，辛＝白虎
*壬，癸＝玄武
 * @param {String} str 
 * @returns 
 */
export const getSixAnimal=(str)=>{
	let firstAnimal=''
	if(str==='甲'||str==='乙'){
		firstAnimal='青龙'
	}
	if(str==='丙'||str==='丁'){
		firstAnimal='朱雀'
	}
	if(str==='戊'){
		firstAnimal='勾陈'
	}
	if(str==='己'){
		firstAnimal='腾蛇'
	}
	if(str==='庚'||str==='辛'){
		firstAnimal='白虎'
	}
	if(str==='壬'||str==='癸'){
		firstAnimal='玄武'
	}
	let index=SIX_ANIMAL.indexOf(firstAnimal)
	let animalList=[]
	let count=0
	while(count<6){
		animalList.push(SIX_ANIMAL[index])
		index++;
		count++;
	}
  return animalList;
}



/**
 * 获取地支索引
 * @param {*} str 
 * @returns 
 */
const getDiZhiIndex=(str)=>{
  return DIZHI.indexOf(str)+1;
}


/**
 * 获取地支属性
 * @param {*} str 
 * @returns 
 */
export const getDiZhiType=(str)=>{
	let res=DIZHI_TYPE[DIZHI.indexOf(str)]
  if(res==='阳'){
		return 'red'
	}else{
		return 'black'
	}
}


/**
 * 获取卦值
 * @param {*} index 
 * @returns 
 */
const getGuaValue=(index)=>{
  if(index===0) return '坤'
  return GUA[index-1];
}

/**
 * 获取卦索引
 * @param {*} str 
 * @returns 
 */
export const getGuaPic=(str)=>{
  let index=GUA.indexOf(str);
  return GUAPIC[index];
}

/**
 * 获取上卦地支索引
 * @param {*} str 
 * @returns 
 */
export const getGuaDizhiUpper=(str)=>{
  let index=GUA.indexOf(str);
  return GUA_DIZHI_UPPER[index];
}

/**
 * 获取下卦地支索引
 * @param {*} str 
 * @returns 
 */
export const getGuaDizhiLower=(str)=>{
  let index=GUA.indexOf(str);
  return GUA_DIZHI_LOWER[index];
}



/**
 * 获取阳历
 * @param {Number} year 
 * @param {Number} month 
 * @param {Number} day 
 * @param {Number} hour 
 * @param {Number} minute 
 * @param {Number} second 
 * @returns {Solar}
 */
export const getSolar=(year,month,day,hour,minute,second)=>{
  return Solar.fromYmdHms(year,month,day,hour, minute,second);
}

/**
 * 公历转阴历
 * @param {Number} year 
 * @param {Number} month 
 * @param {Number} day 
 * @param {Number} hour 
 * @param {Number} minute 
 * @param {Number} second 
 * @returns {Lunar}
 */
export const getLunarFromGongli=(year,month,day,hour,minute,second)=>{
  const solar = Solar.fromYmdHms(year,month,day,hour, minute,second);
  return solar.getLunar();
}

/**
 * 公历转阴历
 * @param {Number} year 
 * @param {Number} month 
 * @param {Number} day 
 * @param {Number} hour 
 * @param {Number} minute 
 * @param {Number} second 
 * @returns {Lunar}
 */
export const getLunarFromNongLi=(year,month,day,hour,minute,second)=>{
  return Lunar.fromYmdHms(year,month,day,hour, minute,second);
}




/**
 * 获取上卦
 * @param {Lunar} lunar 
 * @param {Lunar} askTimeLunar 
 * @returns 
 */
export function calculateUpperGua(lunar,askTimeLunar) {

  const bazi=lunar.getBaZi();
  const baziCurrentTime=askTimeLunar.getBaZi();

  let totalBazi=0
  let totalBaziCurrent=0

  for(let i=0;i<bazi.length;i++){
    let first=bazi[i].substring(0,1);
    let second=bazi[i].substring(1,2);
    let firstIndex=getTianGanIndex(first)
    let secondIndex=getDiZhiIndex(second)
    totalBazi+=firstIndex+secondIndex
  }

  //上卦由主命四柱，加上咨询时间前三柱，然后除以8求余数
  for(let i=0;i<baziCurrentTime.length-1;i++){
    let first=baziCurrentTime[i].substring(0,1);
    let second=baziCurrentTime[i].substring(1,2);
    let firstIndex=getTianGanIndex(first)
    let secondIndex=getDiZhiIndex(second)
    totalBaziCurrent+=firstIndex+secondIndex
  }
	//console.log('上卦',(totalBazi+totalBaziCurrent))
  let guaIndex=(totalBazi+totalBaziCurrent)%8;
	
  return getGuaValue(guaIndex);
}


/**
 * 获取下卦
 * @param {Lunar} lunar 
 * @param {Lunar} askTimeLunar 
 * @returns 
 */
export function calculateLowerGua(lunar,askTimeLunar) {

  const bazi=lunar.getBaZi();
  const lunarCurrentTime=askTimeLunar;
  const baziCurrentTime=lunarCurrentTime.getBaZi();

  let totalBazi=0
  let totalBaziCurrent=0

  for(let i=0;i<bazi.length;i++){
    let first=bazi[i].substring(0,1);
    let second=bazi[i].substring(1,2);
    let firstIndex=getTianGanIndex(first)
    let secondIndex=getDiZhiIndex(second)
    totalBazi+=firstIndex+secondIndex
  }

  for(let i=0;i<baziCurrentTime.length;i++){
    let first=baziCurrentTime[i].substring(0,1);
    let second=baziCurrentTime[i].substring(1,2);
    let firstIndex=getTianGanIndex(first)
    let secondIndex=getDiZhiIndex(second)
    totalBaziCurrent+=firstIndex+secondIndex
  }
	//console.log('下卦',(totalBazi+totalBaziCurrent))
  let guaIndex=(totalBazi+totalBaziCurrent)%8;
	
  return getGuaValue(guaIndex);
}


/**
 * 获取动爻索引
 * @param {Lunar} lunar 
 * @param {Lunar} askTimeLunar 
 * @returns 
 */
export function calculateDongYao(lunar,askTimeLunar) {
 	
  const bazi=lunar.getBaZi();
  const baziCurrentTime=askTimeLunar.getBaZi()
  let totalBazi=0
  let totalBaziCurrent=0

  for(let i=0;i<bazi.length;i++){
    let first=bazi[i].substring(0,1);
    let second=bazi[i].substring(1,2);
    let firstIndex=getTianGanIndex(first)
    let secondIndex=getDiZhiIndex(second)
    totalBazi+=firstIndex+secondIndex
  }

  for(let i=0;i<baziCurrentTime.length;i++){
    let first=baziCurrentTime[i].substring(0,1);
    let second=baziCurrentTime[i].substring(1,2);
    let firstIndex=getTianGanIndex(first)
    let secondIndex=getDiZhiIndex(second)
    totalBaziCurrent+=firstIndex+secondIndex
  }
	//console.log('动爻',(totalBazi+totalBaziCurrent))
  let guaIndex=(totalBazi+totalBaziCurrent)%6;
  if(guaIndex===0) guaIndex=6;
  return guaIndex;
}

/**
 * 获取变卦
 * @param {Lunar} lunar 
 * @param {Lunar} askTimeLunar 
 * @returns 
 */
export function calculateBianGua(lunar, askTimeLunar) {
  let bianhuaIndex = calculateDongYao(lunar, askTimeLunar);
  if (bianhuaIndex > 3) {
    let upperGua = calculateUpperGua(lunar,askTimeLunar);
    let upperGuaIndex = GUA.indexOf(upperGua);
    let guaPic = GUAPIC[upperGuaIndex];
    if (bianhuaIndex === 4) {
      guaPic[2] = guaPic[2][0] === 1 && guaPic[2][1] === 1 ? [1, 0] : [1, 1];
    } else if (bianhuaIndex === 5) {
      guaPic[1] = guaPic[1][0] === 1 && guaPic[1][1] === 1 ? [1, 0] : [1, 1];
    } else {
      guaPic[0] = guaPic[0][0] === 1 && guaPic[0][1] === 1 ? [1, 0] : [1, 1];
    }
    let mapKey=guaPic[0][0]+''+guaPic[0][1]+''+guaPic[1][0]+''+guaPic[1][1]+''+guaPic[2][0]+''+guaPic[2][1];
    return GUA_MAP.get(mapKey);
  } else {
    let lowerGua = calculateLowerGua(lunar, askTimeLunar);
    let lowerGuaIndex = GUA.indexOf(lowerGua);
    let guaPic = GUAPIC[lowerGuaIndex];
    if (bianhuaIndex === 1) {
      guaPic[2] = guaPic[2][0] === 1 && guaPic[2][1] === 1 ? [1, 0] : [1, 1];
    } else if (bianhuaIndex === 2) {
      guaPic[1] = guaPic[1][0] === 1 && guaPic[1][1] === 1 ? [1, 0] : [1, 1];
    } else {
      guaPic[0] = guaPic[0][0] === 1 && guaPic[0][1] === 1 ? [1, 0] : [1, 1];
    }
    let mapKey=guaPic[0][0]+''+guaPic[0][1]+''+guaPic[1][0]+''+guaPic[1][1]+''+guaPic[2][0]+''+guaPic[2][1];
    return GUA_MAP.get(mapKey);
  }
}


/**
 * 获取变卦地支
 * @param {Lunar} lunar 
 * @param {Lunar} askTimeLunar 
 * @returns 
 */
export function calculateBianGuaDizhi(lunar,askTimeLunar) {
  let bianhuaIndex=calculateDongYao(lunar,askTimeLunar)
	let bianGua=calculateBianGua(lunar,askTimeLunar)
  if(bianhuaIndex>3){
    let upperGuaIndex=GUA.indexOf(bianGua)
    return GUA_DIZHI_UPPER[upperGuaIndex]
  }else{
    let lowerGuaIndex=GUA.indexOf(bianGua)
    return GUA_DIZHI_LOWER[lowerGuaIndex]
  }
}


/**
 * 获取上卦地支
 * @param {String} upperGua 
 */
export function calculateUpperGuaDizhi(upperGua) {
    let upperGuaIndex=GUA.indexOf(upperGua)
    return GUA_DIZHI_UPPER[upperGuaIndex]
}

/**
 * 获取下卦地支
 * @param {String} lowerGua 
 */
export function calculateLowerGuaDizhi(lowerGua) {
  let lowerGuaIndex=GUA.indexOf(lowerGua)
  return GUA_DIZHI_LOWER[lowerGuaIndex]
}

/**
 * askTimeLunarArray:['癸','卯','乙','丑','癸','酉','癸','亥','寅','卯']
 * dizhiArray:['子','戌','申','辰','寅','子']
 * @param {String[]} askTimeLunarArray 
 * @param {String[]} dizhiArray 
 */
export function calculateScore(askTimeLunarArray,dizhiArray){
  let scores=[]
  for(let dizhi of dizhiArray){
    let curMap=SCORE_MAP.get(dizhi)
    let curMapArr=curMap.split(",")
    let count=0;
    for(let item of curMapArr){
      count+=askTimeLunarArray.filter(char => char === item).length;
    }
    scores.push(count+'-'+(10-count))
  }
  return scores;
}



/**
 * 日期格式化
 * @param {Date} date 
 * @returns 
 */
export const dateFormat=(date)=>{
  const formattedDateTime = date.getFullYear() + '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
    ('0' + date.getDate()).slice(-2) + ' ' +
    ('0' + date.getHours()).slice(-2) + ':' +
    ('0' + date.getMinutes()).slice(-2) + ':' +
    ('0' + date.getSeconds()).slice(-2);

  return formattedDateTime;
}





/**
 * 五运六气
 * @param {Number} year 
 * @returns 
 */
export function calculateWuYunLiQi(year) {
  const wuyunTable = ['甲', '己', '土', '甲', '己', '土', '金', '金', '水', '水', '木', '木', '火', '火'];
  const liqiTable = ['厥阴风木', '少阴君火', '少阳相火', '太阴湿土', '阳明燥金', '太阳寒水'];
  const wuyunIndex = year % 10;
  const liqiIndex = parseInt(((year % 10) / 5 * 2).toFixed(0));
  const wuyun = wuyunTable[wuyunIndex];
  const liuqi = liqiTable[liqiIndex];
	//console.log(year, wuyun, liqiIndex,liuqi);
  return { wuyun, liuqi };
}

