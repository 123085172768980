import { createHashRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/home/Home";
import History from "../pages/history/History";
import About from "../pages/about/About";

const router = createHashRouter([
    {
      path: "/",
      element: <App/>,
      children:[
        {
            path:'',
            element:<Home/>
        },
        {
            path:'history',
            element:<History/>
        },
        {
            path:'about',
            element:<About/>
        }
      ]
    },
  ]);

  export default router;