import React from 'react'

export default function About() {
   
  return (
    <div>
       <p>生辰八字v1.0</p>
       <p>仅限于自用，不得用于任何其他非法用途，一切用途跟开发者无关。</p>
    </div>
  )
}
